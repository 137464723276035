import React from "react"
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import SEO from '../components/SEO'
import styled from 'styled-components'

const LostSection = styled.div`


text-align:center;
  h1{
    display:none;
  }

  .lost-in-nature-img{
    max-width:980px;
    margin: 0 auto 48px;
  }
  
`


const NotFoundPage = () => {
  


  return(
    <Layout>
      <SEO title="404"/>
      <LostSection>
   
        <h1>404</h1>
        <StaticImage src="../images/404-cap.png" alt="perdus dans la nature" objectFit="contain" layout="constrained" />
        
        <p>Vous avez pris un chemin de traverse qui ne vous mènera nullepart... la tristesse.</p>
        <Link className="go-home" to='/'>Retour à la case départ.</Link>
      </LostSection>
    </Layout>
  )
}

export default NotFoundPage
